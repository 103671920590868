import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Config from "./config/Links";
import axios from "axios";

// Auth
import Welcome from "./Intro/Welcome";
import Auth from "./auth/Login";

// Components && Pages
import Navigation from "./routes/Navigation";
import NotFound from "./routes/children/NotFound";
import Home from "./routes/children/Home";
import Controleur from "./routes/children/Controleur";
import Colecteur from "./routes/children/Colecteur";
import CollecteurInfo from "./components/colecteur/ColecteurInfo";
import Carnet from "./routes/children/Carnet";
import Boutique from "./routes/children/Store";
import Tasks from "./routes/children/Tasks";
import Notification from "./components/Notification";
import Profil from "./components/Profil";
import Desistement from "./routes/children/Desistement";
import Raport from "./routes/children/Raport";
import AddControleur from "./components/controleur/CreateControleur";
import AddCarnet from "./components/carnet/CreateCarnet";
import CreateTask from "./components/tasks/CreateTasks";
import AddProduct from "./components/store/CreateStore";
import Clients from "./routes/children/Clients";
import Settings from "./routes/children/Seettings";
import ClientInfo from "./components/client/InfoClient";
import TasksArchive from "./components/tasks/TasksArchive";
import AgenceInfo from "./components/dashboard/AgencesDetails";
import CollectorPendingPayDatail from "./components/dashboard/ColPayDetail";
import CollectorPendingPayDatailEpargne from "./components/dashboard/ColPayDetailEpargne";
import AlternativeHome from "./routes/AlternativeHome";
import StockAgence from "./routes/children/CarnetManager";
import AgenceAddStock from "./components/stock_agence/CreateStock";
import StockGeneral from "./routes/children/Stock_general";
import Litige from "./routes/children/Litige";
import Gestion_client from "./routes/children/Gestion_client";
import Desistement_Groupe from "./routes/children/Desstement_Groupe";

// Lgg Componenets && Pages
import NavigationLgg from "./lgg_addOn/nav/NavigationLgg";
import Children_DashboardLgg from "./lgg_addOn/nav/children/DashboardLgg";
import Children_CarnetsLgg from "./lgg_addOn/nav/children/CarnetLgg";
import Children_VendeursLgg from "./lgg_addOn/nav/children/VendeursLgg";
import Children_ClientLgg from "./lgg_addOn/nav/children/ClientsLgg";
import Children_AgencesLgg from "./lgg_addOn/nav/children/AgencesLgg";
import Children_BonusLgg from "./lgg_addOn/nav/children/BonusLgg";
import Children_OrdersLgg from "./lgg_addOn/nav/children/OrdersLgg";
import Children_MagasinLgg from "./lgg_addOn/nav/children/MagasinLgg";
import Children_SettingsLgg from "./lgg_addOn/nav/children/SettingsLgg";

const App = () => {
  const token = sessionStorage.getItem("admin_tk");
  const url = Config.G_PERFORM_LINK;
  const [autoRisation, setAutorisation] = useState({});

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/connected`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchAdminConnect = async () => {
      await axios
        .request(config)
        .then((response) => {
          setAutorisation(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAdminConnect();
  }, []);

  const adminType = sessionStorage.getItem("admin_type");

  return (
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/gperf/login" element={<Auth.GPerfLogin />} />
      <Route path="/gvendor/login" element={<Auth.GVendorLogin />} />
      <Route path="*" element={<NotFound />} />

      {/* Layout */}
      {token !== null && (
        <Route
          path="/dashboard"
          element={adminType == 0 ? <Navigation /> : <NavigationLgg />}
        >
          {/* SideBar */}
          <Route
            index
            element={
              autoRisation.dashboard_auto == 1 && adminType == 0 ? (
                <AlternativeHome />
              ) : adminType == 1 ? (
                <Children_DashboardLgg />
              ) : (
                <Home />
              )
            }
          />
          {/* Gperformance */}
          <Route path="/dashboard/controleur" element={<Controleur />} />
          <Route path="/dashboard/colecteur" element={<Colecteur />} />
          <Route path="/dashboard/desistement" element={<Desistement />} />
          <Route path="/dashboard/carnet" element={<Carnet />} />
          <Route path="/dashboard/store" element={<Boutique />} />
          <Route path="/dashboard/tasks" element={<Tasks />} />
          <Route path="/dashboard/raport" element={<Raport />} />
          <Route path="/dashboard/stock/agence" element={<StockAgence />} />
          <Route path="/dashboard/settings" element={<Settings />} />
          <Route path="/dashboard/stock/general" element={<StockGeneral />} />
          <Route path="/dashboard/litige" element={<Litige />} />
          <Route
            path="/dashboard/gestion_client"
            element={<Gestion_client />}
          />
          <Route
            path="/dashboard/desistement_groupe"
            element={<Desistement_Groupe />}
          />

          {/* G-Vendor */}
          <Route
            path="/dashboard/carnet/lgg"
            element={<Children_CarnetsLgg />}
          />
          <Route
            path="/dashboard/vendeur/lgg"
            element={<Children_VendeursLgg />}
          />
          <Route
            path="/dashboard/clients/lgg"
            element={<Children_ClientLgg />}
          />
          <Route
            path="/dashboard/agence/lgg"
            element={<Children_AgencesLgg />}
          />
          <Route path="/dashboard/bonus/lgg" element={<Children_BonusLgg />} />
          <Route
            path="/dashboard/orders/lgg"
            element={<Children_OrdersLgg />}
          />
          <Route
            path="/dashboard/magasin/lgg"
            element={<Children_MagasinLgg />}
          />
          <Route
            path="/dashboard/settings/lgg"
            element={<Children_SettingsLgg />}
          />

          {/* Header */}
          <Route path="/dashboard/notification" element={<Notification />} />
          <Route path="/dashboard/profil" element={<Profil />} />
          <Route
            path="/dashboard/colecteur/info/:id"
            element={<CollecteurInfo />}
          />

          {/* Others */}
          <Route path="/dashboard/clients" element={<Clients />} />
          <Route path="/dashboard/add/controleur" element={<AddControleur />} />
          <Route path="/dashboard/create/carnet" element={<AddCarnet />} />
          <Route path="/dashboard/create/product" element={<AddProduct />} />
          <Route path="/dashboard/create/task" element={<CreateTask />} />
          <Route path="/dashboard/task/archives" element={<TasksArchive />} />
          <Route
            path="/dashboard/colecteur/agence/info/:id"
            element={<AgenceInfo />}
          />
          <Route
            path="/dashboard/payment/pending/produit/col/:id"
            element={<CollectorPendingPayDatail />}
          />

          <Route
            path="/dashboard/payment/pending/epargne/col/:id"
            element={<CollectorPendingPayDatailEpargne />}
          />

          <Route
            path="/dashboard/colecteur/client/info/:id"
            element={<ClientInfo />}
          />

          <Route
            path="/dashboard/agence/add/carnet/:id/:name"
            element={<AgenceAddStock />}
          />
        </Route>
      )}
    </Routes>
  );
};

export default App;
